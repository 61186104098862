// 提供产品相关的API函数
import request from '/utils/request'

/**
 * 获取产品详情
 * @param productId
 */
export const findProducts = (productId) => {
  return request('/front/product/product/detail?url=&appId=10002', 'get', {productId})
}

/**
 * 获取产品类别
 */
export const findProductCategory = () => {
  return request('/front/product/category/index?url=&appId=10002', 'get')
}

/**
 * 通过类别获取产品
 * @param {Object} params - 可参考接口文档
 */
export const findProductByCategoryId = (params) => {
  params.appId = 10002
  return request('/front/product/product/lists?url=&appId=10002', 'post', params)
}


/**
 * 获取相关推荐产品|猜你喜欢产品
 * @param {Integer} location - 10销量排序20时间排序30
 */
export const findRelevantGoods = (location = 10) => {
  return request('/front/product/product/recommendProduct?url=&appId=10002', 'get', {location})
}


export const findGoodsHot = (location = 10) => {
  return request('/front/product/product/recommendProduct?url=&appId=10002&location=10', 'post')
}
